import React from "react"

const Rectangle = () => {
  return (
    <svg
      class="absolute top-0 hidden lg:block"
      width="700"
      height="400"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <title>Rectangle</title>
        <rect
          transform="rotate(-35 182.307 168.065)"
          stroke="null"
          id="svg_4"
          height="134"
          width="884.22612"
          y="101.06464"
          x="-259.80558"
          fill="#FA2742"
        />
      </g>
    </svg>
  )
}

export default Rectangle
