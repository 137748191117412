import React from "react"
import SEO from "./SEO"
import Rectangle from "./Rectangle"

const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <Rectangle/>
      <div className="flex flex-col min-h-screen background z-30">
        {children}
        <footer className="py-2 text-center text-primary-red text-xs">
          &copy; Kay Hoogland 2022
        </footer>
      </div>
    </>
  )
}

export default Layout
